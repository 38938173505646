
import { Options, Vue } from 'vue-class-component';
import { apiUserGetList,
         apiUserGetListTotalNum } from '@/services/tpsApi/tpsApiUser';
import { apiCompanyGetListName } from '@/services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '@/services/tpsApi/tpsApiBrand';
import { apiUploadFile } from '@/services/tpsApi/tpsApiImportByFile';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '@/services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '@/services/exportFile';
import { IUserList } from '@/services/tpsApi/models/user';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import UserForm from '@/components/UserForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    UserForm,
    Table,
    Pagination
  }
})
export default class User extends Vue {
    $refs!: {
        form: UserForm,
        alert: Alert,
        file
    }

    TABLE_HEAD: {} = {
        name: "使用者",
        tel: "電話",
        address: "公司地址",
        brand_id: "所屬品牌",
        company_id: "所屬公司",
        permission: "權限",
        invalid: "狀態"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    userList: IUserList | Blob = [];
    file: string | Blob = "";
    formData: {} = {};
    filter: string = "";
    filter_company: string = "";
    filter_brand: string = "";
    companySelectOptions: {} = {};
    brandSelectOptions: {} = {};
    pageNo: {} = PAGENO;
    
    async mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
        this.companySelectOptions = await apiCompanyGetListName();
        this.brandSelectOptions = await apiBrandGetListName();
    }

    async initPagination() {
        this.pageTotalNum = await apiUserGetListTotalNum({  filter: this.filter,
                                                            filter_company: this.filter_company,
                                                            filter_brand: this.filter_brand });
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {  filter: this.filter,
                                        filter_company: this.filter_company,
                                        filter_brand: this.filter_brand });
        this.userList = await apiUserGetList(pData);
    } 
    
    importFile() {
        this.$refs.file.value = null;
        this.$refs.file.click();
    }

    doSearch() {
        this.filter = this.formData["filter"];
        this.filter_company = this.formData["filter_company"];
        this.filter_brand = this.formData["filter_brand"];
        
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }

    async download(pData: { output: string, }) {
        const res = await apiUserGetList({ output: pData.output, 
                                           filter: this.filter,
                                           filter_company: this.filter_company,
                                           filter_brand: this.filter_brand});
        exportFile({ fileName: "帳號", data: res });
    }
    
    async fileUpload(event) {
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append('file', this.file);
        console.log(this.file)
        const res = await apiUploadFile({table:"account", data:formData});
        if(res.msg === ("ok")) this.$refs.alert.doAlert("success", "上傳成功");
    }
}
