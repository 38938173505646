
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { apiUserGetDetail,
         apiUserDetailUpdate,
         apiUserCreate } from '../services/tpsApi/tpsApiUser';
import { apiCompanyGetListName } from '../services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import { IUserDetail } from '../services/tpsApi/models/user';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import WarningModal from '@/components/WarningModal.vue';
import { PAGENO } from '@/services/common/IPageNo.js';
import ReportTaichungSearchMenu from './ReportTaichungSearchMenu.vue';

@Options({
  components: {
    WarningModal
  },
  emits: ["hide-form"]
})


export default class UserForm extends Vue {
vShow(vShow: any) {
throw new Error('Method not implemented.');
}
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: IUserDetail = {} as any;
    formData: IUserDetail = {} as any;
    actionType: string = "";
    userID: number = -1;
    companySelectOptions: {} = {};
    brandSelectOptions: {} = {};
    accountWarningText: string = "";
    passwordWarningText: string = "";
    ex_perm_list: any;
    PAGENO: any = PAGENO;
    notAdmin: boolean = false;

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
        this.notAdmin = this.$store.getters.permission === 'admin' ? false : true;
        this.ex_perm_list = {};
    }

    async mounted() {
        this.companySelectOptions = await apiCompanyGetListName();
        this.brandSelectOptions = await apiBrandGetListName();

    }

    removeComBrand() {
        this.formData.brand_id = '0';
        this.formData.company_id = '0';
    }

    debug() {
        console.log(this.ex_perm_list);
    }

    async initForm(pActionType, pUserID) {
        this.actionType = pActionType;
        this.userID = pUserID;
        this.accountWarningText = "";
        this.passwordWarningText = ""
        if(pUserID) {
            this.formData = Object.assign({}, await apiUserGetDetail({id:pUserID}));
            // console.log(this.formData.extra_permission)
            let list = JSON.parse(this.formData.extra_permission)['enable']!;
            list.map(i => {
                this.ex_perm_list[i] = true;
            });
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            this.formData["invalid"] = 0;
            this.formData['brand_id'] = Object.keys(this.brandSelectOptions)[0];
            this.formData['company_id'] = Object.keys(this.companySelectOptions)[0];
        }
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave) {
            if(!this.formData.e_mail) this.accountWarningText = "帳號不能為空白";
            if(!this.formData.password) this.passwordWarningText = "密碼不能為空白";

            if (this.formData.permission === 'admin') {
                this.formData.company_id = '0';
                this.formData.brand_id = '0';
                this.ex_perm_list[10] = true;
                this.formData.write = 1;
            }
                        
            let enList : number[] = [];
            Object.keys(this.ex_perm_list).forEach((k) => {
                if (this.ex_perm_list[k] === true) enList.push(+k);
            })
            let perEnable = {
                enable: enList
            }
            this.formData.extra_permission = JSON.stringify(perEnable);
            
            if(this.formData.e_mail && this.formData.password) {
                //Set default modified_time & modified_user
                let dt = new Date(); 
                this.formData["modified_time"] = `${
                    (dt.getMonth()+1).toString().padStart(2, '0')}-${
                    dt.getDate().toString().padStart(2, '0')}-${
                    dt.getFullYear().toString().padStart(4, '0')} ${
                    dt.getHours().toString().padStart(2, '0')}:${
                    dt.getMinutes().toString().padStart(2, '0')}:${
                    dt.getSeconds().toString().padStart(2, '0')}`;
                this.formData["modified_user"] = this.$store.getters.currentUser;

                if(this.actionType == "update") {
                    try {
                        let res = await apiUserDetailUpdate({id: this.userID, data: arrangeUpdateData(this.formData,this.originalData)});
                        if(res.msg === ("ok")) this.hideForm(true);
                        else this.hideForm(false);
                    } catch(err) {
                        if (err.alert) {
                            alert(err.msg);
                        }
                    }
                }
                else if(this.actionType == "create") {
                    try{
                        let res = await apiUserCreate(this.formData);
                        if(res.msg === ("ok")) this.hideForm(true);
                        else this.hideForm(false);
                    }
                    catch(err) {
                        if(err?.data?.msg == "account is exist") {
                            this.accountWarningText = "此帳號已存在";
                        }
                        else if (err.alert) {
                            alert(err.msg);
                        }

                    }
                }        
            }    
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }
    
    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
